import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import {Color, FontSize, LineHeight} from './Widget';
import * as AppHooks from '../Hooks/AppHooks';
import * as SvgIcon from './SvgIcon';
import FixedRatioImage from './FixedRatioImage';
import {formatDateStr} from '../Utils/DateUtil';
import * as L from '../Utils/Lang';
import {NavActions} from '../Contexts/AppContext';
import SummaryFromHtmlString from '../Utils/SummaryFromHtmlString';

function NewsSlider(props) {
  let {data = [], breakpoints} = props;
  const [activeIdx, setActiveIdx] = useState(0);
  const [triggered, setTriggered] = useState(true);
  const {dimension, desktop} = AppHooks.useDimension();

  useEffect(() => {
    setTriggered(true);
    setTimeout(() => {
      setTriggered(false);
    }, 300);
  }, [activeIdx]);

  if (!dimension || data.length === 0) {
    return null;
  }

  return (
    <Carousel
      wrapAround={data.length > 1}
      autoplay={false}
      heightMode={'max'}
      beforeSlide={index => setActiveIdx(index)}
      renderBottomCenterControls={({
        goToSlide,
        nextSlide,
        previousSlide,
        slideCount,
        currentSlide,
      }) => {
        return (
          <InfoWrapper breakpoints={breakpoints}>
            <div className={`info${triggered ? ' triggered' : ''}`}>
              <div
                className="info-title"
                onClick={() => {
                  NavActions.navigate(
                    `/news/detail?id=${data[currentSlide]?.id}`,
                  );
                }}>
                {L.d(data[currentSlide]?.title)}
              </div>
              <div className="info-description-wrapper">
                <div className="info-description-content">
                  {SummaryFromHtmlString(L.d(data[currentSlide]?.description))}
                </div>
              </div>
              <div className="info-date">
                {formatDateStr(data[currentSlide]?.date, 'YYYY/MM/DD')}
              </div>
            </div>
          </InfoWrapper>
        );
      }}
      getControlsContainerStyles={key => {
        switch (key) {
          case 'BottomRight':
            return {
              bottom: dimension.innerWidth > breakpoints?.mobile ? 66 : 0,
            };
          case 'BottomCenter':
            return {
              width: '100%',
            };
          default:
            return {};
        }
      }}
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      renderBottomRightControls={({previousSlide, nextSlide}) =>
        data.length > 1 ? (
          <ArrowsWrapper breakpoints={breakpoints}>
            <div className="info-description-arrow">
              <SvgIcon.ChevronLeft
                color={Color.mainDark}
                size={28}
                onClick={previousSlide}
              />
            </div>
            <div className="info-description-arrow">
              <SvgIcon.ChevronRight
                color={Color.mainDark}
                size={28}
                onClick={nextSlide}
              />
            </div>
          </ArrowsWrapper>
        ) : null
      }>
      {data.map((d, idx) => {
        return (
          <Wrapper key={idx} breakpoints={breakpoints}>
            <div
              className="banner"
              onClick={() => {
                NavActions.navigate(`/news/detail?id=${d.id}`);
              }}>
              <FixedRatioImage
                mode="cover"
                ratio={9 / 16}
                image={d.banner}
                width={'100%'}
              />
            </div>

            <InfoWrapper
              breakpoints={breakpoints}
              className={'mobile-placeholder'}>
              <div className="info">
                <div
                  className="info-title"
                  onClick={() => {
                    NavActions.navigate(`/news/detail?id=${d.id}`);
                  }}>
                  }}>
                  {L.d(d.title)}
                </div>
                <div className="info-description-wrapper">
                  <div className="info-description-content" />
                  {SummaryFromHtmlString(L.d(d.description))}
                </div>
                <div className="info-date">
                  {formatDateStr(d.date, 'YYYY/MM/DD')}
                </div>
              </div>
            </InfoWrapper>
          </Wrapper>
        );
      })}
    </Carousel>
  );
}

const ArrowsWrapper = styled.div`
  display: flex;
  padding-right: 30px;
  & > .info-description-arrow {
    width: 40px;
    height: 40px;
    border: 1px solid ${Color.mainDark};

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & > .info-description-arrow:last-child {
    border-left-width: 0px;
  }

  & > .info-description-arrow:hover {
    background-color: ${Color.mainDark};
    border: none;
    & > div {
      & > svg {
        color: ${Color.mainWhite};
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    padding-right: 0px;
    & > .info-description-arrow {
      width: 32px;
      height: 32px;
      & > div {
        width: 24px;
        height: 24px;
        & > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  position: relative;

  & > .info {
    position: relative;
    left: 20%;

    /*position: absolute;
  left: 20%;
  bottom: 0px;*/

    padding: 24px 0px 24px 40px;
    background-color: white;
    width: 80%;

    & > .info-title {
      font-size: ${FontSize.title}px;
      line-height: ${LineHeight.title}px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }

    & > .info-description-wrapper {
      display: flex;
      justify-content: space-between;

      & > .info-description-content {
        font-size: ${FontSize.meta}px;
        line-height: ${LineHeight.meta}px;
        color: ${Color.mainDark_70};
        margin-right: 140px;
        font-weight: 300;
        white-space: pre-line;

        /* line-clamp css*/
        height: 38px;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
      }

      margin-top: 8px;
      margin-bottom: 20px;
    }

    & > .info-date {
      font-size: ${FontSize.meta}px;
      line-height: ${LineHeight.meta}px;
      color: ${Color.mainDark_70};
    }
  }

  & > .info {
    & > .info-title {
      transform: translateY(0px);
      opacity: 1;
      transition: all 0.3s ease-in;
    }
    & > .info-description-wrapper {
      transform: translateY(0px);
      opacity: 1;
      transition: all 0.3s ease-in;
      transition-delay: 0.1s;
    }
    & > .info-date {
      transform: translateY(0px);
      opacity: 1;
      transition: all 0.3s ease-in;
      transition-delay: 0.2s;
    }
  }

  & > .info.triggered {
    & > .info-title {
      transform: translateY(15px);
      opacity: 0;
      transition: all 0s ease-in;
    }

    & > .info-description-wrapper {
      transform: translateY(15px);
      opacity: 0;
      transition: all 0s ease-in;
    }
    & > .info-date {
      transform: translateY(15px);
      opacity: 0;
      transition: all 0s ease-in;
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    /*padding-bottom: 0px;*/
    padding-top: 16px;
    & > .info {
      position: relative;
      padding: 0px 0px 32px 0px;
      width: 100%;
      left: 0px;

      & > .info-title {
        font-size: ${FontSize.highlight}px;
        line-height: ${LineHeight.highlight}px;
      }

      & > .info-description-wrapper {
        & > .info-description-content {
          font-size: ${FontSize.meta}px;
          line-height: ${LineHeight.meta}px;
          margin-right: 0px;
        }
      }

      & > .info-date {
        font-size: ${FontSize.meta}px;
        line-height: ${LineHeight.meta}px;
      }
    }
  }

  &.mobile-placeholder {
    display: none;
  }
  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    &.mobile-placeholder {
      display: block;
      visibility: hidden;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 100px;

  & * {
    &:focus {
      outline: none; /* overwrite border of nuka-carousel */
      background-color: yellow;
    }
  }

  & > .banner {
    cursor: pointer;
    width: 100%;
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    padding-bottom: 0px;
  }
`;

export default NewsSlider;
