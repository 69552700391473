import React, {useEffect, useMemo, useState, Fragment} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import ActionCreator from '../ActionCreator';
import * as AppContext from '../Contexts/AppContext';
import {Actions, NavActions} from '../Contexts/AppContext';
import {useDimension} from '../Hooks/AppHooks';
import * as L from '../Utils/Lang';
import {
  Color,
  FontSize,
  LineHeight,
  TextBanner,
  TextHighLightS,
  TextParagraph,
  TextTitle,
} from '../Components/Widget';
import HomeSlider from '../Components/HomeSlider';
import ProductSlider from '../Components/ProductSlider';
import NewsSlider from '../Components/NewsSlider';
import FixedRatioImage from '../Components/FixedRatioImage';
import AnimIndexIndicator from '../Components/AnimIndexIndicator';
import BottomUpSheet from '../Components/BottomUpSheet';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../Components/RectButton';
import {goTo} from '../Utils/UrlUtils';
import {brandNameToBrandId} from '../Domain/Brand';

function _getMostChildSubCat(catTree) {
  if (!catTree) {
    return null;
  }
  let {cat} = catTree.reduce(
    (acc, cur, idx) => {
      if (cur.collections.length > acc.max) {
        return {max: cur.collections.length, maxCatId: cur.id, cat: cur};
      }
      return acc;
    },
    {max: 0, maxCatId: null, cat: null},
  );
  return cat;
}

function HomePage(props) {
  const {app} = props;
  const [shopItemActiveIdx, setShopItemActiveIdx] = useState(0);
  const [catActiveId, setCatActiveId] = useState(null);
  const [trackingCat, setTrackingCat] = useState(false);
  const {dimension, mobile, tablet, desktop} = useDimension();
  const bottomSheetRef = React.useRef();
  const [mobileActiveCat, setMobileActiveCat] = useState(null);
  const isMobile = dimension?.innerWidth <= app.breakpoints.mobile;

  useEffect(() => {
    const _fetch = async () => {
      AppContext.Actions.setLoading(true);
      try {
        await AppContext.Actions.loadHomeData();
        await AppContext.Actions.fetchAllSeries(
          brandNameToBrandId[app.activeBrand],
        );
      } catch (err) {
        console.error(err);
      }
      AppContext.Actions.setLoading(false);
    };

    _fetch();
  }, [app.activeBrand]);

  let mostChildCat = useMemo(
    () => _getMostChildSubCat(app?.homeData?.categories),
    [app],
  );

  const getImage = (data, video) => {
    if (video) {
      return '';
    }
    if (desktop) {
      return data.image;
    } else if (tablet) {
      return data.image_m;
    } else if (mobile) {
      return data.image_s;
    } else {
      return data.image;
    }
  };

  if (!app.homeData) {
    return <div style={{height: '100vh'}} />;
  }

  let {
    homeData: {
      promotion,
      banners,
      shop_items,
      categories,
      collection,
      sections,
      news,
      about,
    },
  } = app;

  return (
    <Wrapper breakpoints={app.breakpoints}>
      {promotion && (
        <>
          <div
            className="promotion"
            data-nav-type="normal"
            onClick={() => {
              goTo(promotion.link_to, NavActions);
            }}>
            <FixedRatioImage
              image={promotion.image?.original}
              ratio={360 / 1440}
              width="100%"
              mode="cover"
              rwd={[
                {
                  ratio: 640 / 1440,
                  breakpoint: app.breakpoints.tablet,
                  image: promotion.image?.mobile,
                },
              ]}
            />
          </div>
          <div style={{height: 1, backgroundColor: '#fff'}} />
        </>
      )}

      <div className="banner" data-nav-type="revert">
        <>
          <HomeSlider
            images={banners}
            breakpoints={app.breakpoints}
            ratio={desktop ? 900 / 1440 : 960 / 480}
          />
          <div style={{height: 1, backgroundColor: '#fff'}} />
        </>
      </div>

      {!!shop_items && shop_items.length > 0 && (
        <>
          <div className="shop_section" data-nav-type="normal">
            <div className="header">
              <TextTitle className="title">SHOP THE LOOK</TextTitle>
              <div className="paging-display">
                <AnimIndexIndicator
                  total={shop_items.length}
                  currIdx={shopItemActiveIdx}
                  width={12}
                  height={28}
                />
                <div className="line" />
                <TextHighLightS>{shop_items.length}</TextHighLightS>
              </div>
            </div>

            <ProductSlider
              activeIdx={shopItemActiveIdx}
              onSliderIdxChanged={idx => setShopItemActiveIdx(idx)}
              ratio={app.brandProductImageRatio[app.activeBrand]}
              images={shop_items.map(item => ({
                ...item,
                images: item.images,
              }))}
            />
          </div>
          <div style={{height: 1, backgroundColor: '#fff'}} />
        </>
      )}

      <FixedRatioImage
        width={'100%'}
        ratio={desktop ? 640 / 1440 : 1024 / 768}
        image={getImage(collection, collection.video)}
        data-nav-type="revert"
        mode={'cover'}>
        <div className="collection section">
          {collection.video && (
            <video
              loop={true}
              style={{objectFit: 'cover', zIndex: 0, position: 'absolute'}}
              autoPlay={true}
              controls={false}
              width="100%"
              height="100%">
              <source src={collection.video} type="video/mp4" />
            </video>
          )}
          <div className="content">
            {isMobile ? (
              <TextTitle style={{color: Color.mainWhite}}>COLLECTION</TextTitle>
            ) : (
              <TextBanner style={{color: Color.mainWhite}}>
                COLLECTION
              </TextBanner>
            )}
            <div
              className="category-wrapper"
              onMouseEnter={() => setTrackingCat(true)}
              onMouseLeave={() => {
                setCatActiveId(null);
                setTrackingCat(false);
              }}>
              <div className="category-buttons">
                {categories.map((category, idx) => (
                  <div
                    className="category-name"
                    style={{
                      opacity:
                        catActiveId === category.id || catActiveId === null
                          ? 1
                          : 0.3,
                    }}
                    key={idx}
                    onClick={() => {
                      if (isMobile) {
                        setMobileActiveCat(category);
                        bottomSheetRef.current.open({
                          title: L.d(category.title),
                          items: category.collections,
                        });
                      }
                    }}
                    onMouseEnter={() => {
                      if (!isMobile) {
                        setCatActiveId(category.id);
                      }
                    }}>
                    <TextParagraph style={{color: Color.mainWhite}}>
                      {L.d(category.title)}
                    </TextParagraph>
                  </div>
                ))}
              </div>

              <div
                className={`subcat-panels-wrapper ${
                  trackingCat && catActiveId !== null ? ' active' : ''
                }`}>
                {
                  <div className={`subcat-panel placeholder`}>
                    {mostChildCat?.collections.map((collection, idx) => (
                      <div className="subcat-item" key={idx}>
                        <TextParagraph>{L.d(collection.title)}</TextParagraph>
                      </div>
                    ))}
                  </div>
                }
                {categories.map((category, idx) => {
                  return (
                    <div
                      key={`${idx}`}
                      className={`subcat-panel ${
                        catActiveId === category.id ? ' active' : ''
                      }`}>
                      {category.collections.map((collection, idx) => (
                        <div
                          key={`${idx}`}
                          className="subcat-item"
                          onClick={e => {
                            NavActions.navigate(
                              `/products?collection_id=${collection.id}`,
                            );
                          }}>
                          {L.d(collection.title)}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </FixedRatioImage>
      <div style={{height: 1, backgroundColor: '#fff'}} />

      {sections.map((s, idx) => {
        return (
          <Fragment key={idx}>
            <FixedRatioImage
              width={'100%'}
              key={idx}
              ratio={desktop ? 640 / 1440 : 1024 / 768}
              image={getImage(s, s.video)}
              data-nav-type="revert"
              mode={'cover'}>
              <CustomSection
                key={idx}
                className="custom section"
                breakpoints={app.breakpoints}>
                {s.video && (
                  <video
                    loop={true}
                    style={{
                      objectFit: 'cover',
                      zIndex: 0,
                      position: 'absolute',
                    }}
                    autoPlay={true}
                    controls={false}
                    width="100%"
                    height="100%">
                    <source src={s.video} type="video/mp4" />
                  </video>
                )}
                <div className="content">
                  {isMobile ? (
                    <TextTitle style={{color: Color.mainWhite}}>
                      {L.d(s.title)}
                    </TextTitle>
                  ) : (
                    <TextBanner style={{color: Color.mainWhite}}>
                      {L.d(s.title)}
                    </TextBanner>
                  )}
                  <div className="buttons-wrapper">
                    {s.buttons.map((b, idx) => {
                      return (
                        <RectButton
                          key={idx}
                          skin={BUTTON_SKIN.CRYSTAL}
                          size={
                            isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE
                          }
                          text={b.content}
                          onClick={() => {
                            goTo(b.link_to, NavActions);
                          }}
                          style={
                            isMobile
                              ? {margin: '12px 0', width: '160px'}
                              : {margin: '0 12px', width: '160px'}
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              </CustomSection>
            </FixedRatioImage>
            <div style={{height: 1, backgroundColor: '#fff'}} />
          </Fragment>
        );
      })}

      {!!news && news.length > 0 && (
        <div className="news section" data-nav-type="solid">
          <div className="header">
            <div className="title">FEATURE</div>
            <div
              onClick={() => {
                NavActions.navigate('/news');
              }}
              className="more">
              ALL NEWS <div className="see-more-arrow" />
            </div>
          </div>
          <div className="content">
            <NewsSlider data={news} breakpoints={app.breakpoints} />
          </div>
        </div>
      )}

      <div className="about section" data-nav-type="revert">
        <HomeSlider
          images={about.banners}
          ratio={desktop ? 640 / 1440 : 1024 / 768}
          breakpoints={app.breakpoints}
        />
      </div>

      <BottomUpSheet
        itemCount={mobileActiveCat?.collections.length || 0}
        getInstance={inst => {
          bottomSheetRef.current = inst;
        }}
      />
    </Wrapper>
  );
}

const CustomSection = styled.div`
  position: relative;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  & > .content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    align-items: center;

    & > .buttons-wrapper {
      display: flex;
      margin-top: 8px;
      margin-bottom: 84px;

      & > .button:last-child {
        margin-right: 0px;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(20, 20, 20, 0.3);
    z-index: 0;
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    & > .content {
      & > .buttons-wrapper {
        margin-top: 16px;
        margin-bottom: 52px;
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & > .content {
      & > .buttons-wrapper {
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & > .content {
      & > .buttons-wrapper {
        margin-top: 18px;
        margin-bottom: 32px;
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & .buttons {
      flex-direction: column;
    }
  }
`;

const Wrapper = styled.div`
  background-color: #fff;

  & > .promotion {
    padding: 0 50px;
    margin-bottom: 50px;
    background-color: white;
  }

  & > .banner,
  & > .about.section {
    background-color: lightgray;
    position: relative;

    & > .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(20, 20, 20, 0.1),
        rgba(20, 20, 20, 0.4)
      );
    }
  }

  & > .shop_section {
    padding-top: 88px;
    background-color: white;

    & > .header {
      padding: 0px 50px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      & > .paging-display {
        display: flex;
        align-items: center;

        & > .line {
          border: 1px solid ${Color.mainDark};
          width: 32px;
          opacity: 0.8;
          margin: 0px 8px;
        }
      }
    }

    @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
      padding-top: 44px;
      & > .header {
        padding: 0px 20px;
        margin-bottom: 20px;
        flex-direction: column;

        & > .paging-display {
          margin-top: 6px;
        }
      }
      & > .header > div > .text__title {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  & .collection.section {
    position: relative;
    width: 100%;
    height: 100%;

    & > .content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      align-items: center;

      & > .category-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 18px;

        & > .category-buttons {
          margin-top: 20px;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          & > .category-name {
            padding: 2px 10px;
            margin-right: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            border-bottom: 1px solid ${Color.mainWhite};
          }

          & > .category-name:last-child {
            margin-right: 0px;
          }
        }

        & > .subcat-panels-wrapper {
          opacity: 0;
          max-height: 0px;
          transition: all 0.3s ease-in;
          position: relative;
          width: calc(100% - 100px);

          & > .subcat-panel {
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.3s ease-in;
            position: absolute;
            bottom: 0px;
            top: 0;
            left: 0;
            right: 0;
            background: white;

            padding: 20px 50px;
            padding-bottom: 42px; /* offset of last row of subcat-item's margin-bottom */
            display: flex;
            flex-wrap: wrap;

            & > .subcat-item {
              flex-basis: 25%;
              margin-bottom: 8px;
              cursor: pointer;
            }
          }

          & > .subcat-panel.placeholder {
            /* placeholder for subcat-panel */
            position: relative;
            opacity: 0;
          }

          & > .subcat-panel.active {
            /* show current cat tree */
            opacity: 1;
            pointer-events: all;
          }
        }

        & > .subcat-panels-wrapper.active {
          /* show panel */
          opacity: 1;
          max-height: 200px;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(20, 20, 20, 0.3);
      z-index: 0;
    }
  }

  & > .news.section {
    max-width: 1212px;

    padding: 44px 50px;
    margin: 0 auto;
    background-color: ${Color.mainWhite};

    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${Color.mainDark};
      margin-bottom: 16px;

      & > .title {
        font-size: ${FontSize.highlight}px;
        line-height: ${LineHeight.highlight}px;
        font-weight: bold;
      }
      & > .more {
        font-size: ${FontSize.paragraph}px;
        line-height: ${LineHeight.paragraph}px;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
          & .see-more-arrow {
            width: 48px;
          }
        }

        & .see-more-arrow {
          position: relative;
          width: 24px;
          background-color: ${Color.mainDark_70};
          height: 1px;
          margin-left: 12px;
          transition: width 0.1s ease-in;
          &:before {
            content: '';
            width: 8px;
            display: block;
            background-color: ${Color.mainDark_70};
            height: 1px;
            transform: rotate(30deg);
            transform-origin: right;
            position: absolute;
            right: 1px;
          }
        }
      }
    }

    & > .content {
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    & > .promotion {
      padding: 0 35px;
      margin-bottom: 35px;
    }

    & .collection.section {
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & > .promotion {
      padding: 0 20px;
      margin-bottom: 20px;
    }

    & .collection.section {
      & > .content {
        & > .category-wrapper {
          & > .subcat-panels-wrapper.active {
            /* always hide panel in mobile mode */
            opacity: 0;
            max-height: 0;
          }
        }
      }
    }

    & > .news.section {
      padding: 44px 20px;
      & > .header {
        & > .title {
          font-size: ${FontSize.highlight_s}px;
          line-height: ${LineHeight.highlight_s}px;
          font-weight: bold;
        }
        & > .more {
          font-size: ${FontSize.meta}px;
          line-height: ${LineHeight.meta}px;
        }
      }
      & > .content {
        height: 420px;
      }
    }
  }
`;

export default AppContext.withConsumer(connect(null, ActionCreator)(HomePage));
