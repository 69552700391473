import React from 'react';
import styled from 'styled-components';
import {TextHighLightS} from '../Components/Widget';

function AnimIndexIndicator(props) {
  const {total, currIdx, width, height} = props;
  const dataArr = Array.from({length: total});
  const baseProps = {width, height, currIdx};
  return (
    <AnimIndexWrapper {...baseProps}>
      <AnimList {...baseProps}>
        {dataArr.map((_, idx) => (
          <AnimIndexItem key={idx} active={idx === currIdx} {...baseProps}>
            <TextHighLightS>{idx + 1}</TextHighLightS>
          </AnimIndexItem>
        ))}
      </AnimList>
    </AnimIndexWrapper>
  );
}

const AnimIndexWrapper = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: relative;
`;

const AnimList = styled.div`
  position: absolute;
  top: 0;
  left: ${props => props.currIdx * -12}px;
  transition: 200ms;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
`;

const AnimIndexItem = styled.div`
  flex: 0 0 ${props => props.width}px;
  height: ${props => props.height}px;
  line-height: ${props => props.height}px;
  text-align: center;
  transition: 200ms;
  opacity: ${props => (props.active ? 1 : 0)};
`;

export default AnimIndexIndicator;
