import React from 'react';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {TextTitle} from './Widget';
import * as L from '../Utils/Lang';
import ActionCreator from '../ActionCreator';
import * as SvgIcon from './SvgIcon';
import AnimProgressCircle from './AnimProgressCircle';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';
import {useDimension} from '../Hooks/AppHooks';
import {goTo} from '../Utils/UrlUtils';
import {NavActions} from '../Contexts/AppContext';
import FixedRatioImage from './FixedRatioImage';

const initialSlideHeight = 600;

function HomeSlider(props) {
  const {ratio = 900 / 1440} = props;
  const {breakpoints, images = []} = props;

  return (
    <Wrapper breakpoints={breakpoints}>
      <Carousel
        initialSlideHeight={initialSlideHeight}
        wrapAround={images.length > 1}
        autoplay
        autoplayInterval={5000}
        renderBottomCenterControls={({currentSlide, goToSlide}) =>
          images.length > 1 ? (
            <div className={'control-bottom-wrapper'}>
              {images.map((_, idx) => {
                const active = currentSlide === idx;
                return (
                  <div
                    key={`${idx + (active ? '-active' : '')}`}
                    onClick={() => goToSlide(idx)}>
                    <AnimProgressCircle size={32} active={active} />
                  </div>
                );
              })}
            </div>
          ) : null
        }
        renderCenterLeftControls={({previousSlide}) =>
          images.length > 1 ? (
            <div className="control-left" onClick={previousSlide}>
              <SvgIcon.ChevronBigLeft color={'white'} size={64} />
            </div>
          ) : null
        }
        renderCenterRightControls={({nextSlide}) =>
          images.length > 1 ? (
            <div className="control-right" onClick={nextSlide}>
              <SvgIcon.ChevronBigRight color={'white'} size={64} />
            </div>
          ) : null
        }>
        {images.map((item, idx) => (
          <SliderItem
            item={item}
            key={idx}
            ratio={ratio}
            breakpoints={breakpoints}
          />
        ))}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  & .control-left,
  & .control-right {
    cursor: pointer;
  }

  & .control-bottom-wrapper {
    display: flex;
    margin-bottom: 20px;

    & .control-bottom {
      padding: 11px;
      border-radius: 50%;
      cursor: pointer;

      & > .inner {
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 50%;
      }
    }

    & .control-bottom.active {
      border: 1px solid white;
    }
  }
`;

function _SliderItem(props) {
  let {item, ratio, breakpoints} = props;
  const {desktop} = useDimension();

  return item.video ? (
    <FixedRatioImage width={'100%'} ratio={ratio} image={''} mode={'cover'}>
      <SliderItemWrapper breakpoints={breakpoints}>
        <video
          loop={true}
          style={{
            position: 'absolute',
            zIndex: -1,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            objectFit: 'cover',
          }}
          autoPlay={true}
          controls={false}
          width="100%"
          height="100%">
          <source src={item.video} type="video/mp4" />
        </video>
        <div className="item-mask" />
        <div className="item-content">
          <TextTitle style={{color: 'white'}}>{item.title}</TextTitle>
          <div className="item-buttons">
            {item.buttons.length > 0 &&
              item.buttons.map((b, index) => {
                return (
                  <RectButton
                    key={index}
                    text={L.d(b.content)}
                    onClick={() => {
                      goTo(b.link_to, NavActions);
                    }}
                    skin={BUTTON_SKIN.CRYSTAL}
                    size={!desktop ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
                    style={
                      !desktop
                        ? {margin: '12px 0', width: '160px'}
                        : {margin: '0 12px', width: '160px'}
                    }
                  />
                );
              })}
          </div>
        </div>
      </SliderItemWrapper>
    </FixedRatioImage>
  ) : (
    <FixedRatioImage
      width={'100%'}
      ratio={ratio}
      image={desktop ? item.image : item.image_tablet}
      mode={'cover'}>
      <SliderItemWrapper breakpoints={breakpoints}>
        <div className="item-mask" />
        <div className="item-content">
          <TextTitle style={{color: 'white'}}>{item.title}</TextTitle>
          <div className="item-buttons">
            {item.buttons.length > 0 &&
              item.buttons.map((b, index) => {
                return (
                  <RectButton
                    key={index}
                    text={L.d(b.content)}
                    onClick={() => {
                      goTo(b.link_to, NavActions);
                    }}
                    skin={BUTTON_SKIN.CRYSTAL}
                    size={!desktop ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
                    style={
                      !desktop
                        ? {margin: '12px 0', width: '160px'}
                        : {margin: '0 12px', width: '160px'}
                    }
                  />
                );
              })}
          </div>
        </div>
      </SliderItemWrapper>
    </FixedRatioImage>
  );
}

let SliderItem = connect(null, ActionCreator)(_SliderItem);

const SliderItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 72px;

  & .item-mask {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(20, 20, 20, 0.1),
      rgba(20, 20, 20, 0.4)
    );
  }

  & .item-content {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    & .item-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & .item-buttons {
      flex-direction: column;
    }
  }
`;

export default HomeSlider;
