import React from 'react';
import SlideInPanel from './SlideInPanel';
import styled from 'styled-components';
import {useDimension} from '../Hooks/AppHooks';
import {Close} from '@styled-icons/material';
import * as L from '../Utils/Lang';
import {FontSize, LineHeight} from './Widget';
import {NavActions} from '../Contexts/AppContext';

function BottomPanelContent(props) {
  const {title, items, close} = props;

  return (
    <Wrapper onClick={close}>
      <Content
        onClick={evt => {
          evt.stopPropagation();
        }}>
        <div className="title-bar">
          <div className="title">{title}</div>
          <Close size={24} onClick={close} />
        </div>
        <div className="scroll-area">
          {items.map((item, idx) => (
            <div
              onClick={() => {
                NavActions.navigate(`/products?collection_id=${item.id}`);
              }}
              key={idx}
              className="option">
              {L.d(item.title)}
            </div>
          ))}
        </div>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: transparent;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 8px;
`;

const Content = styled.div`
  max-width: calc(100vw - 16px);
  margin: 0 auto;
  height: 100%;
  background-color: white;
  border-radius: 2px;
  position: relative;

  & > .title-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 1;

    & > .title {
      flex: 1;
    }
  }

  & > .scroll-area {
    padding: 52px 20px 12px 20px;

    height: 100%;
    overflow: auto;

    & > .option {
      padding: 5px 0px;
      font-size: ${FontSize.meta}px;
      line-height: ${LineHeight.meta}px;
      font-family: 'Gotham-Book';
    }
  }
`;

function BottomUpSheet(props) {
  const {dimension} = useDimension();
  const {itemCount} = props;

  const fullHeight = itemCount ? itemCount * 32 + 80 + 52 + 8 : 0;
  return (
    <SlideInPanel
      position="bottom"
      size={
        (fullHeight && fullHeight > dimension?.innerHeight
          ? dimension?.innerHeight
          : fullHeight) ||
        dimension?.innerHeight ||
        500
      }
      style={{backgroundColor: 'transparent'}}
      getInstance={inst => {
        props.getInstance({
          open: async dataProps => {
            inst.open(
              <BottomPanelContent
                close={inst.close.bind(inst)}
                {...dataProps}
              />,
            );
          },
          close: () => {
            inst.close();
          },
        });
      }}
    />
  );
}

export default BottomUpSheet;
