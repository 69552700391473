import React from 'react';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Color} from './Widget';
import ActionCreator from '../ActionCreator';
import * as SvgIcon from './SvgIcon';
import FixedRatioImage from './FixedRatioImage';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';
import {useDimension} from '../Hooks/AppHooks';
import {goTo} from '../Utils/UrlUtils';
import {NavActions} from '../Contexts/AppContext';

function ProductSlider(props) {
  let {
    images = [],
    ratio = 5 / 4,
    activeIdx = 0,
    onSliderIdxChanged = () => 0,
    breakpoints = {tablet: 1280, mobile: 640},
  } = props;

  return (
    <Wrapper breakpoints={breakpoints}>
      <Carousel
        wrapAround={images.length > 1}
        slideIndex={activeIdx}
        afterSlide={idx => onSliderIdxChanged(idx)}
        autoplay={false}
        heightMode="max"
        renderBottomCenterControls={() => null}
        getControlsContainerStyles={key => {
          switch (key) {
            case 'CenterRight':
            case 'CenterLeft':
              return {
                top: 0,
                bottom: 0,
              };
            default:
              return {};
          }
        }}
        renderCenterLeftControls={({previousSlide}) =>
          images.length > 1 ? (
            <div className="control-left" onClick={previousSlide}>
              <div className="control-icon-wrapper">
                <SvgIcon.ChevronBigLeft color={Color.mainDark} size={28} />
              </div>
            </div>
          ) : null
        }
        renderCenterRightControls={({nextSlide}) =>
          images.length > 1 ? (
            <div className="control-right" onClick={nextSlide}>
              <div className="control-icon-wrapper">
                <SvgIcon.ChevronBigRight color={Color.mainDark} size={28} />
              </div>
            </div>
          ) : null
        }>
        {images.map((item, idx) => (
          <SliderItem
            item={item}
            key={idx}
            ratio={ratio}
            breakpoints={breakpoints}
          />
        ))}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .slider-control-centerright,
  & .slider-control-centerleft {
    /* overwrite nuka-carousel style */
    transform: none !important;

    justify-content: center;
    display: flex;
    align-items: center;
  }

  position: relative;
  & .control-left,
  & .control-right {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: calc(100vw / 3 / 2);
    height: 100%;

    & > .control-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${Color.mainDark};
      width: 40px;
      height: 40px;
      margin-left: 50px;
      margin-right: 50px;
      flex-shrink: 0;
    }
  }

  & .control-right {
    justify-content: flex-end;
  }
  & .control-left {
    justify-content: flex-start;
  }

  & .control-left > .control-icon-wrapper:hover,
  & .control-right > .control-icon-wrapper:hover {
    background-color: ${Color.mainDark};
    border: none;
    & > div {
      & > svg {
        color: ${Color.mainWhite};
      }
    }
  }

  & .control-bottom-wrapper {
    display: flex;
    margin-bottom: 20px;

    & .control-bottom {
      padding: 11px;
      border-radius: 50%;
      cursor: pointer;

      & > .inner {
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 50%;
      }
    }

    & .control-bottom.active {
      border: 1px solid white;
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    & .control-left,
    & .control-right {
      width: 40px;
      height: 40px;

      & > .control-icon-wrapper {
        margin-left: 35px;
        margin-right: 35px;
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & .control-left,
    & .control-right {
      width: 32px;
      height: 32px;

      & > .control-icon-wrapper {
        width: 32px;
        height: 32px;
        & > svg {
          width: 23px;
          height: 23px;
        }

        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
`;

function _SliderItem(props) {
  let {item, ratio = 5 / 4, breakpoints = {tablet: 1280, mobile: 640}} = props;
  const {dimension, mobile, tablet} = useDimension();
  const isMobile = dimension?.innerWidth <= breakpoints.mobile;
  const smallDevice = mobile || tablet;

  return (
    <SliderItemWrapper breakpoints={breakpoints}>
      <div className="images-row">
        {smallDevice ? (
          <div className="image">
            <FixedRatioImage
              ratio={ratio}
              width={window.innerWidth}
              className={'image'}
              image={item.images[0]}
              mode="cover"
            />
          </div>
        ) : (
          item.images.map((image, idx) => {
            return (
              <div className="image" key={`${idx}`}>
                <FixedRatioImage
                  key={idx}
                  ratio={ratio}
                  width={(window.innerWidth - 15) / 3}
                  className={'image'}
                  image={image}
                  mode="cover"
                />
              </div>
            );
          })
        )}
      </div>
      <div className="container">
        <div className="buttons">
          {item.buttons.length > 0 &&
            item.buttons.map((b, idx) => {
              return (
                <RectButton
                  skin={BUTTON_SKIN.PRIMARY}
                  text={b.content}
                  key={idx}
                  onClick={() => {
                    goTo(b.link_to, NavActions);
                  }}
                  size={isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
                  style={
                    isMobile
                      ? {margin: '12px 0', width: '150px'}
                      : {margin: '0 12px', width: '150px'}
                  }
                />
              );
            })}
        </div>
      </div>
    </SliderItemWrapper>
  );
}

let SliderItem = connect(null, ActionCreator)(_SliderItem);

const SliderItemWrapper = styled.div`
  position: relative;

  & > .images-row {
    display: flex;
    height: 100%;
    & > .image {
      flex: 1;
      background-size: cover;
      background-position: center;
    }
  }

  background: url(${props => props.image || ''});
  background-size: cover;
  background-position: center;

  & > .container {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    & .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 32px;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & .buttons {
      flex-direction: column;
    }
  }
`;

export default ProductSlider;
